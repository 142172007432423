<template>
	<main class="layer-popup-container">
		<div class="layer-popup layer-popup-alert layer-popup-compnay-reg">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">사업자 등록번호 인증</h3>
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body">
						<div class="mbrp-cert-desc text-left">
							<p>※ 사업자 등록번호 정보를 입력하면 플랫폼의 모든 기능을 사용할 수 있습니다. (일반회원의 경우 통합기업회원이 사용할 수 있는 서비스 일부를 사용할 수 없습니다.)</p>
						</div>
						<div class="mbrp-cert-wrap display-flex width-full">
							<div class="flex-l">
								<div class="field field-row">
									<div class="field-label w120"><label for="">사업자 등록번호</label></div>
									<div class="control field-form">
										<input type="text" class="input" placeholder="사업자 등록번호를 입력하세요 (ex 112-04-35945)" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w120"><label for="">대표자명</label></div>
									<div class="control field-form">
										<input type="text" class="input" placeholder="대표명을 입력하세요" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w120"><label for="">개업일자</label></div>
									<div class="control field-form">
										<input type="text" class="input" placeholder="개업일자를 입력하세요 (ex 20100705)" />
									</div>
								</div>
							</div>
							<div class="flex-r">
								<button type="button" class="button-default is-secondary">인증하기</button>
							</div>
						</div>
					</div>
					<div class="popup-buttons">
						<button class="button-default is-primary is-rounded" @click="clickClose">
							<span class="text">닫기</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MUT_CLOSE_BROADCAST_CALENDAR_MODAL } from '../../store/_mut_consts';
import { ACT_GET_PROG_SCHEDULE_LIST } from '@/store/_act_consts';
import { getItems } from '@/assets/js/utils';

export default {
	name: 'CompanyRegCertModal',
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('common', ['isMobile']),
	},
	data: () => ({}),
	created() {
		//console.log('BroadcastCalendarModal!!!');
	},
	mounted() {},
	methods: {
		clickClose() {
			//
		},
	},
};
</script>
