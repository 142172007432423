<template>
	<div class="selfdx-item">
		<div class="flex-l">
			<section class="selfdx-msection-sub">
				<div class="selfdx-msection-sub__header">
					<i class="icon icon-selfdx-main-11"></i>
					<h4 class="selfdx-msection-sub__title">기본정보</h4>
				</div>
				<div class="selfdx-msection-sub__body">
					<div class="selfdx-msection-sub__img">
						<img :src="profile" alt="" />
					</div>
					<div class="selfdx-msection-sub__info">
						<dl>
							<dt>대상</dt>
							<dd>{{ findEnums(arrAge, diagnAge) }} {{ findEnums(arrGender, diagnGender) }}</dd>
						</dl>
						<dl>
							<dt>지역</dt>
							<dd>{{ diagnAddr }} {{ diagnAddrDetail }}</dd>
						</dl>
						<dl>
							<dt>업종</dt>
							<dd>{{ findEnums(arrSector, diagnSector) }}</dd>
						</dl>
						<dl>
							<dt>연차</dt>
							<dd>{{ findEnums(arrExper, diagnExper) }}</dd>
						</dl>
					</div>
				</div>
			</section>
		</div>
		<div class="flex-r">
			<section class="selfdx-msection-sub">
				<div class="selfdx-msection-sub__header">
					<i class="icon icon-selfdx-main-12"></i>
					<h4 class="selfdx-msection-sub__title">분석</h4>
					<!-- <h5 class="selfdx-msection-sub__title">{{regDtTimeFormat(regDt)}}</h5> -->
				</div>
				<div class="selfdx-msection-sub__body">
					<div class="selfdx-msection-sub__graph">
						<dl>
							<dt>정보/기술 습득</dt>
							<dd>
								<div v-if="showGraph1">
									<transition name="fade">
										<transition<circular-progress-graph :value="String(analyTchnlgy)" color="#FBB635" />
									</transition>
								</div>
								<div v-else class="dxloader-small-container">
									<span class="dxloader-small"></span>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>온라인커머스/역량</dt>
							<dd>
								<div v-if="showGraph2">
									<transition name="fade">
										<circular-progress-graph :value="String(analyOnline)" color="#0EA7E6" />
									</transition>
								</div>
								<div v-else class="dxloader-small-container">
									<span class="dxloader-small"></span>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>벤치마킹/분석</dt>
							<dd>
								<div v-if="showGraph3">
									<transition name="fade">
										<circular-progress-graph :value="String(analyBncmrk)" color="#EE5B42" />
									</transition>
								</div>
								<div v-else class="dxloader-small-container">
									<span class="dxloader-small"></span>
								</div>
							</dd>
						</dl>
						<dl>
							<dt>지원시설/사업활용</dt>
							<dd>
								<div v-if="showGraph4">
									<transition name="fade">
										<circular-progress-graph :value="String(analySrnst)" color="#08D1DE" />
									</transition>
								</div>
								<div v-else class="dxloader-small-container">
									<span class="dxloader-small"></span>
								</div>
							</dd>
						</dl>
					</div>
					<div class="selfdx-msection-sub__desc" v-if="showText">
						<i class="selfdx-voice"><em></em><em></em><em></em></i>
						<text-typing :content="prompt" />
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import CircularProgressGraph from '@/components/common/CircularProgressGraph';
import TextTyping from '@/components/common/TextTyping';
import { Gender, Age, Sector, Exper, SectorDetail } from '../../../store/modules/diagn';
import { timestampToDateFormat } from '../../../assets/js/utils';

export default {
	name: 'SelfDxResultMain',
	props: {
		name: {
			type: String,
			default: '',
		},
		profileUrl: {
			type: String,
			default: '',
		},
		diagnAge: {
			type: String,
			default: '',
		},
		diagnGender: {
			type: String,
			default: '',
		},
		diagnAddr: {
			type: String,
			default: '',
		},
		diagnAddrDetail: {
			type: String,
			default: '',
		},
		diagnSector: {
			type: String,
			default: '',
		},
		diagnExper: {
			type: String,
			default: '',
		},
		analyTchnlgy: {
			type: Number,
			default: 0,
		},
		analyOnline: {
			type: Number,
			default: 0,
		},
		analyBncmrk: {
			type: Number,
			default: 0,
		},
		analySrnst: {
			type: Number,
			default: 0,
		},
		prompt: {
			type: String,
			default: '',
		},
		regDt: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		profile() {
			if (this.profileUrl) {
				return require(`@/assets/img/selfdx/${this.profileUrl}`);
			}
		},
	},
	components: { CircularProgressGraph, TextTyping },
	data: () => ({
		isVisible: false,
		showGraph1: false,
		showGraph2: false,
		showGraph3: false,
		showGraph4: false,
		showText: false,
		arrAge: Age,
		arrGender: Gender,
		arrSector: Sector,
		arrSectorDetail: SectorDetail,
		arrExper: Exper,
	}),
	mounted() {
		setTimeout(() => {
			this.showGraph1 = true;
			this.showGraph2 = true;
			this.showGraph3 = true;
			this.showGraph4 = true;
		}, 1000);

		setTimeout(() => {
			this.showText = true;
		}, 1000);
	},
	methods: {
		findEnums(arr, val) {
			return arr.find((item) => item.value === val)?.label;
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm');
		},
	},
};
</script>
